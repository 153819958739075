import React from "react";
import { Main } from "./components/main/containers/Main";
import { Video } from "./components/video/Video";
import { AboutContainer } from "./components/about/containers/AboutContainer";


export const StripPlasticPage = () => (
    <section>
        <Main />
        <Video />
        <AboutContainer />
        </section>
  );