import React from "react";
import { Typography } from "@mui/material";
import {INTL } from "../../../../../common/constants";
import { PageTemplate } from "../../../../../common/ui/organisms/PageTemplate";

export const AboutContainer = () => (
  <PageTemplate
    title={INTL.DIRECTIONS_PAGE.STRIP_PLASTIC_PRO}
    description={
      "Это невероятно красивый, женственный танец, раскрывающий внутреннюю красоту каждой представительницы прекрасного пола, ее магическую притягательность, шарм, обаяние. Это искусство быть женщиной! Исполняется он на стрипах — специальной обуви на каблуках и платформе, в которой натягивается подъем стопы. Большинство элементов исполняется в партере, то есть на полу. В нем много интересных акробатических элементов, заимствованных из других стилей, но адаптированных под исполнение на стрипах."
    }
    groupLevel={"У нас есть две группы — начинающие и продолжающие"}
    wear={
      "Удобную одежду, в которой вы будете чувствовать себя уверенно. Обувь - стрипы или носочки."
    }
    schedule={
      <>
        <Typography>Курс с '0': вт - 19:00, чт - 19:00</Typography>
        <Typography>Группа начинающих: пн - 20:00, ср - 19:00</Typography>
        <Typography>Группа продолжающих: вт - 20:00, чт - 20:00</Typography>
      </>
    }
  />
);
