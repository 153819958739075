import React from "react";
type TIconProps = {
  className?: string;
};

export const LogoIcon = ({ className }: TIconProps) => (
  <svg
    className={className}
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66 59.7867C64 61.2867 62.9042 62.5294 60.5 63.7867C54.7157 66.8118 47.0287 66.0662 39.777 58.6035C32.9179 67.4621 17.5063 67.5258 11 57.2868C11 57.2868 13.5 59.7867 14 60.2867C17.4938 63.2612 20.4304 64.702 25.5363 64.7867C30.5363 64.5209 34.5938 62.5186 36.5363 58.2868C38.4432 54.1324 35.5835 48.3654 30.5 45.2867C25.4126 42.2058 23.5197 41.9832 18.5 38.7867C8 31.459 11.1264 18.9937 17 15.2867C25.1929 10.1159 34 12.2868 39.5 18.7868C44.8616 12.8345 51.2079 9.84299 59.5 13.7868C65.5 17.7868 68 19.7868 67.5 28.2868C66.5 35.7868 57 41.7868 51 43.7868C44.8073 47.0691 40.713 54.9996 43 59.2867C45.6721 64.2957 55.2444 66.1189 61 62.7867C64.4905 60.7659 66.5 59.2867 69 55.7867C69.9637 54.2064 70 54.2867 69.5 55.7867C68.5 57.7867 66.7379 59.2333 66 59.7867ZM48.5 35.7867C51.4336 34.125 54.6105 32.5219 57.5 30.7867C64.5 26.2867 64.7495 18.217 58.5 15.0167C54.4479 12.9417 46.5 11.7868 42.5 16.7868C47 16.7868 49 20.2867 48 23.7867C46.6497 26.5036 43 27.7867 40 25.2867C39 24.2867 38.9245 25.0949 38 25.7867C34.5 27.7867 32.1635 26.4458 31 23.2867C30.5 19.7867 32.5 16.7867 37 16.7867C35.5 15.2867 31.6488 13.2868 27.5 13.2868C22.5 13.2868 20.6643 13.7356 18 17.2867C15.5856 21.5697 15.7821 26.0385 19 28.7867C19.5 30.2867 30.5848 35.6106 32.5 36.7867C37.0226 39.5641 36.5 39.7867 39.5 42.7867C41.3451 41.3753 45.2923 37.7344 48.5 35.7867Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39 77C59.9868 77 77 59.9868 77 39C77 18.0132 59.9868 1 39 1C18.0132 1 1 18.0132 1 39C1 59.9868 18.0132 77 39 77ZM39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z"
      fill="currentColor"
    />
  </svg>
);

export const ArrowRightIcon = ({ className }: TIconProps) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="20"
    viewBox="0 0 11 20"
    fill="none"
  >
<path d="M0.823337 19.9766C0.673495 19.9766 0.527027 19.9327 0.402457 19.8506C0.277889 19.7685 0.180813 19.6518 0.123508 19.5153C0.0662032 19.3788 0.0512425 19.2287 0.0805184 19.0837C0.109794 18.9388 0.181992 18.8058 0.28798 18.7013L9.09056 10.0228L0.28798 1.34417C0.14998 1.2033 0.0736205 1.01463 0.0753465 0.818789C0.0770727 0.622951 0.156747 0.435617 0.297209 0.297134C0.43767 0.158651 0.62768 0.0800991 0.826316 0.0783974C1.02495 0.0766955 1.21632 0.151979 1.3592 0.288036L10.6976 9.49495C10.8397 9.63502 10.9194 9.82496 10.9194 10.023C10.9194 10.2211 10.8397 10.411 10.6976 10.5511L1.3592 19.758C1.28882 19.8274 1.20527 19.8823 1.11332 19.9198C1.02138 19.9574 0.92284 19.9767 0.823337 19.9766Z" fill="white"/>
  </svg>
);

export const CircleLogoIcon = ({ className }: TIconProps) => (
<svg className={className} width="99" height="98" viewBox="0 0 99 98" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.8305 95.4545C47.8731 95.8144 48.046 96.0938 48.349 96.2926C48.652 96.4915 49.0237 96.5909 49.464 96.5909C49.786 96.5909 50.0677 96.5388 50.3092 96.4347C50.5483 96.3305 50.7354 96.1873 50.8703 96.005C51.0029 95.8227 51.0692 95.6155 51.0692 95.3835C51.0692 95.1894 51.023 95.0225 50.9307 94.8828C50.836 94.7455 50.7152 94.6307 50.5684 94.5384C50.4217 94.4484 50.2678 94.3738 50.1068 94.3146C49.9458 94.2578 49.7979 94.2116 49.6629 94.1761L48.9243 93.9773C48.7349 93.9276 48.5242 93.8589 48.2922 93.7713C48.0578 93.6837 47.8341 93.5642 47.621 93.4126C47.4056 93.2635 47.228 93.0717 47.0883 92.8374C46.9487 92.603 46.8788 92.3153 46.8788 91.9744C46.8788 91.5814 46.9818 91.2263 47.1878 90.9091C47.3914 90.5919 47.6897 90.3397 48.0827 90.1527C48.4733 89.9657 48.9479 89.8722 49.5067 89.8722C50.0275 89.8722 50.4785 89.9562 50.8596 90.1243C51.2384 90.2924 51.5367 90.5268 51.7545 90.8274C51.97 91.1281 52.0919 91.4773 52.1203 91.875H51.2112C51.1875 91.6004 51.0952 91.3731 50.9342 91.1932C50.7709 91.0156 50.5649 90.883 50.3163 90.7955C50.0654 90.7102 49.7955 90.6676 49.5067 90.6676C49.1705 90.6676 48.8686 90.7221 48.6011 90.831C48.3336 90.9422 48.1217 91.0961 47.9655 91.2926C47.8092 91.4915 47.7311 91.7235 47.7311 91.9886C47.7311 92.2301 47.7986 92.4266 47.9335 92.5781C48.0684 92.7296 48.246 92.8527 48.4662 92.9474C48.6863 93.0421 48.9243 93.125 49.18 93.196L50.0748 93.4517C50.643 93.6151 51.0928 93.8482 51.4243 94.1513C51.7557 94.4543 51.9214 94.8509 51.9214 95.3409C51.9214 95.7481 51.8113 96.1032 51.5912 96.4062C51.3686 96.7116 51.0703 96.9484 50.6963 97.1165C50.3199 97.2869 49.8997 97.3722 49.4356 97.3722C48.9669 97.3722 48.5502 97.2881 48.1856 97.12C47.8211 96.9543 47.5322 96.727 47.3192 96.4382C47.1037 96.1494 46.9901 95.8215 46.9782 95.4545H47.8305Z" fill="currentColor"/>
<path d="M41.6333 97.476L42.6683 90.2773L43.54 90.4026L42.505 97.6013L41.6333 97.476Z" fill="currentColor"/>
<path d="M39.3783 89.6564L40.2642 89.9165L35.6529 96.1423L34.7807 95.8862L34.2674 88.1557L35.1533 88.4158L35.5137 94.9023L35.5682 94.9183L39.3783 89.6564ZM38.2508 92.2862L34.68 91.2377L34.9001 90.4881L38.4709 91.5366L38.2508 92.2862Z" fill="currentColor"/>
<path d="M33.7859 87.8793L30.7647 94.4948L28.5294 93.4739C28.0126 93.2379 27.6287 92.9559 27.3777 92.6278C27.1257 92.302 26.9899 91.9497 26.9701 91.5712C26.9504 91.1926 27.0296 90.8084 27.2076 90.4186C27.3856 90.0288 27.6231 89.7196 27.9202 89.4909C28.2172 89.2622 28.5698 89.1369 28.978 89.1151C29.3852 89.0955 29.845 89.2027 30.3575 89.4367L32.1665 90.2628L31.836 90.9864L30.0529 90.1721C29.6998 90.0108 29.3919 89.9327 29.1293 89.9377C28.8646 89.9417 28.6406 90.0164 28.4571 90.1616C28.2705 90.308 28.1167 90.5137 27.9958 90.7786C27.8748 91.0435 27.8186 91.2976 27.8272 91.5409C27.8358 91.7843 27.9242 92.0095 28.0926 92.2165C28.2599 92.4256 28.5223 92.6118 28.8798 92.7751L30.2882 93.4183L32.9848 87.5134L33.7859 87.8793ZM29.3148 89.429L29.044 85.7137L29.9743 86.1385L30.2193 89.842L29.3148 89.429Z" fill="currentColor"/>
<path d="M25.2296 90.9568L24.8072 91.614L20.2186 88.665L20.6409 88.0078L22.5648 89.2442L26.0744 83.7832L26.8153 84.2594L23.3057 89.7204L25.2296 90.9568Z" fill="currentColor"/>
<path d="M19.2379 87.92L18.4757 87.2595L19.1745 83.373L19.1101 83.3171L15.3625 84.5619L14.6003 83.9015L19.3862 82.3911L21.3489 80.126L22.0145 80.7027L20.0518 82.9678L19.2379 87.92Z" fill="currentColor"/>
<path d="M14.2993 71.9464L15.5127 73.8345L9.39447 77.7664L8.12734 75.7947C7.74592 75.2012 7.54203 74.6147 7.51565 74.035C7.48728 73.4566 7.62882 72.9084 7.94027 72.3902C8.24972 71.8733 8.72111 71.4114 9.35444 71.0044C9.99176 70.5948 10.6134 70.3557 11.2194 70.2871C11.8234 70.2198 12.3883 70.3238 12.9143 70.5993C13.4383 70.876 13.9 71.3251 14.2993 71.9464ZM14.3793 73.516L13.6728 72.4166C13.3477 71.9108 12.9807 71.5542 12.5718 71.3471C12.1628 71.1399 11.7257 71.0705 11.2602 71.1389C10.7948 71.2072 10.3131 71.4014 9.81522 71.7214C9.3213 72.0388 8.94793 72.3927 8.6951 72.7831C8.44029 73.1748 8.32032 73.5925 8.33519 74.036C8.34808 74.4808 8.50811 74.9422 8.81529 75.4202L9.57557 76.6032L14.3793 73.516Z" fill="currentColor"/>
<path d="M11.9986 68.0958L12.3822 68.9357L4.65734 69.5277L4.27969 68.7008L9.78585 63.2505L10.1694 64.0903L5.50331 68.6105L5.52691 68.6621L11.9986 68.0958ZM9.27284 68.9659L7.72684 65.5806L8.43749 65.256L9.98349 68.6413L9.27284 68.9659Z" fill="currentColor"/>
<path d="M1.30138 59.9867L8.27951 57.9377L8.51962 58.7554L4.15723 64.1667L4.17724 64.2349L9.65616 62.6261L9.90428 63.4711L2.92614 65.5201L2.68603 64.7023L7.05806 59.2734L7.03805 59.2053L1.54549 60.818L1.30138 59.9867Z" fill="currentColor"/>
<path d="M2.43135 52.5103L2.55668 53.382C2.31336 53.4696 2.10606 53.5915 1.93478 53.7476C1.76316 53.9015 1.62605 54.079 1.52345 54.2804C1.41816 54.4797 1.34855 54.6942 1.31461 54.9239C1.28067 55.1536 1.28088 55.3879 1.31524 55.627C1.37791 56.0628 1.54482 56.4418 1.81596 56.764C2.08678 57.0838 2.45159 57.3172 2.9104 57.4641C3.36888 57.6087 3.90978 57.6361 4.53311 57.5465C5.15643 57.4569 5.66768 57.2781 6.06686 57.0103C6.46571 56.7401 6.75 56.4134 6.91973 56.0302C7.08913 55.6447 7.14249 55.234 7.07983 54.7981C7.04546 54.5591 6.97964 54.3342 6.88235 54.1234C6.78507 53.9125 6.65902 53.7261 6.50419 53.5642C6.34668 53.4003 6.16393 53.2687 5.95593 53.1695C5.74524 53.0682 5.512 53.0097 5.25619 52.9939L5.13086 52.1221C5.5082 52.1349 5.85462 52.207 6.17012 52.3387C6.48563 52.4703 6.76384 52.6503 7.00477 52.8788C7.24336 53.1076 7.43846 53.3749 7.59007 53.6808C7.74134 53.9843 7.84308 54.3177 7.89531 54.6809C7.98358 55.2948 7.91211 55.8624 7.68089 56.3836C7.44968 56.9047 7.07727 57.3422 6.56366 57.6958C6.05006 58.0495 5.41364 58.2809 4.6544 58.3901C3.89516 58.4993 3.21932 58.4565 2.62687 58.2619C2.03443 58.0672 1.55387 57.7524 1.18519 57.3175C0.816513 56.8826 0.588038 56.3582 0.499765 55.7442C0.447542 55.381 0.451245 55.0325 0.510874 54.6986C0.570166 54.3624 0.683216 54.0508 0.850024 53.7637C1.01449 53.477 1.22954 53.226 1.49518 53.0108C1.75848 52.796 2.07053 52.6291 2.43135 52.5103Z" fill="currentColor"/>
<path d="M8 51.1076H0.727273L0.727273 46.7184H1.50852L1.50852 50.2269H3.96591L3.96591 46.9457H4.74716L4.74716 50.2269H7.21875L7.21875 46.6616H8V51.1076Z" fill="currentColor"/>
<path d="M4.39362 33.7344C4.03634 33.6739 3.71961 33.761 3.44343 33.9958C3.16725 34.2305 2.96713 34.5591 2.84307 34.9816C2.75236 35.2906 2.72296 35.5755 2.75488 35.8366C2.78746 36.0954 2.8722 36.3152 3.00909 36.496C3.14664 36.6746 3.32673 36.7965 3.54933 36.8619C3.7356 36.9166 3.90875 36.9193 4.06878 36.8701C4.22721 36.8179 4.37139 36.7344 4.50134 36.6196C4.62901 36.5041 4.74391 36.3774 4.84606 36.2396C4.94593 36.1012 5.03191 35.9722 5.104 35.8528L5.50291 35.2001C5.60397 35.0323 5.7292 34.8495 5.87861 34.6516C6.02869 34.4514 6.20643 34.2704 6.41184 34.1087C6.61564 33.944 6.84966 33.8276 7.11389 33.7596C7.37812 33.6917 7.67379 33.7057 8.00089 33.8017C8.37796 33.9124 8.68968 34.1113 8.93604 34.3983C9.18306 34.683 9.34094 35.0403 9.40967 35.47C9.47907 35.8975 9.43506 36.3793 9.27766 36.9154C9.13092 37.4151 8.92322 37.8242 8.65456 38.1425C8.38656 38.4586 8.07764 38.6788 7.7278 38.8031C7.37862 38.9251 7.00922 38.9437 6.6196 38.8589L6.87572 37.9866C7.14589 38.0413 7.38996 38.0167 7.60796 37.913C7.82434 37.8062 8.00957 37.646 8.16365 37.4321C8.31613 37.2154 8.43305 36.9684 8.51442 36.6913C8.60914 36.3687 8.64193 36.0638 8.61281 35.7764C8.58142 35.4884 8.49346 35.2417 8.34894 35.0364C8.20216 34.8305 8.00156 34.6902 7.74715 34.6155C7.51545 34.5474 7.30791 34.5568 7.12451 34.6436C6.94111 34.7304 6.77297 34.8661 6.62008 35.0507C6.46719 35.2352 6.32066 35.4402 6.18048 35.6655L5.68303 36.4521C5.36622 36.9512 5.01575 37.3171 4.63162 37.5498C4.24748 37.7824 3.82032 37.8297 3.35011 37.6916C2.95941 37.5769 2.64969 37.3712 2.42097 37.0746C2.19064 36.7751 2.04752 36.4221 1.99163 36.0159C1.93413 35.6067 1.97074 35.1795 2.10147 34.7343C2.23353 34.2845 2.43156 33.9084 2.69555 33.6059C2.95727 33.3028 3.25671 33.0897 3.59387 32.9666C3.93169 32.8413 4.27831 32.8246 4.63373 32.9167L4.39362 33.7344Z" fill="currentColor"/>
<path d="M4.24014 31.0654L3.52949 30.7409L5.79539 25.7793L6.50604 26.1038L5.55602 28.1841L11.4609 30.8807L11.095 31.6818L5.19017 28.9852L4.24014 31.0654Z" fill="currentColor"/>
<path d="M8.9805 20.6768L9.45663 19.9359L13.5076 22.5393C13.9258 22.8081 14.2359 23.1466 14.4378 23.555C14.639 23.9601 14.7196 24.4017 14.6797 24.8797C14.6378 25.3566 14.4626 25.8349 14.1542 26.3149C13.8457 26.7949 13.4833 27.153 13.067 27.3892C12.6487 27.6241 12.2129 27.7351 11.7595 27.7224C11.3055 27.7064 10.8693 27.564 10.4511 27.2952L6.40017 24.6918L6.8763 23.951L10.8675 26.5159C11.1662 26.7079 11.4743 26.8131 11.7918 26.8314C12.1073 26.8484 12.4099 26.7783 12.6995 26.6211C12.9883 26.4606 13.2422 26.2101 13.4611 25.8695C13.6799 25.5289 13.8024 25.1939 13.8284 24.8645C13.8524 24.5338 13.7904 24.2294 13.6426 23.9515C13.4941 23.6703 13.2704 23.4337 12.9717 23.2417L8.9805 20.6768Z" fill="currentColor"/>
<path d="M17.3215 21.8154L15.8518 23.5116L10.3554 18.7489L11.8903 16.9777C12.3523 16.4445 12.8576 16.0836 13.4064 15.895C13.9533 15.7048 14.5193 15.6861 15.1042 15.839C15.6873 15.9903 16.2633 16.3124 16.8323 16.8055C17.4048 17.3016 17.8094 17.8307 18.0459 18.3928C18.2807 18.9533 18.34 19.5247 18.2239 20.107C18.106 20.6877 17.8052 21.2572 17.3215 21.8154ZM15.8381 22.3344L16.6939 21.3468C17.0877 20.8923 17.3263 20.4397 17.4099 19.989C17.4935 19.5383 17.4369 19.0992 17.2402 18.6719C17.0435 18.2446 16.7215 17.8371 16.2742 17.4496C15.8305 17.0651 15.3857 16.8065 14.9398 16.674C14.4922 16.5398 14.0577 16.5424 13.6363 16.6816C13.2132 16.8193 12.8156 17.1028 12.4435 17.5322L11.5226 18.595L15.8381 22.3344Z" fill="currentColor"/>
<path d="M17.5991 11.2991L22.3617 16.7955L21.6961 17.3722L16.9335 11.8759L17.5991 11.2991Z" fill="currentColor"/>
<path d="M27.3746 8.85768C27.7893 9.50296 28.0311 10.1355 28.1002 10.7553C28.1692 11.375 28.0825 11.9429 27.8401 12.459C27.5976 12.975 27.2155 13.4007 26.6937 13.736C26.1719 14.0713 25.6259 14.2421 25.0558 14.2483C24.4857 14.2545 23.9331 14.0974 23.398 13.7772C22.8629 13.4569 22.388 12.9741 21.9733 12.3288C21.5586 11.6836 21.3168 11.051 21.2477 10.4313C21.1787 9.81149 21.2654 9.24359 21.5078 8.72756C21.7503 8.21153 22.1324 7.78585 22.6542 7.45051C23.176 7.11516 23.722 6.9444 24.2921 6.93822C24.8622 6.93205 25.4148 7.08909 25.9499 7.40936C26.485 7.72962 26.9599 8.2124 27.3746 8.85768ZM26.6576 9.31845C26.3171 8.78868 25.9412 8.39852 25.5297 8.14797C25.1202 7.89614 24.7049 7.77326 24.2839 7.77932C23.8648 7.7841 23.4701 7.90552 23.0996 8.14358C22.7292 8.38165 22.4538 8.69092 22.2734 9.0714C22.0949 9.4506 22.0342 9.87939 22.0912 10.3578C22.1501 10.8349 22.3499 11.3383 22.6903 11.8681C23.0308 12.3978 23.4057 12.7886 23.8152 13.0405C24.2267 13.291 24.642 13.4139 25.061 13.4091C25.4821 13.4031 25.8778 13.281 26.2483 13.0429C26.6187 12.8049 26.8931 12.4962 27.0716 12.117C27.252 11.7366 27.3127 11.3078 27.2537 10.8307C27.1968 10.3523 26.998 9.84822 26.6576 9.31845Z" fill="currentColor"/>
<path d="M37.1134 7.41609C36.8385 7.49679 36.5678 7.50348 36.3013 7.43616C36.0342 7.36657 35.7991 7.2382 35.5961 7.05107C35.3954 6.86327 35.2547 6.63194 35.174 6.35708C35.0926 6.07996 35.0859 5.80928 35.1539 5.54505C35.2235 5.27787 35.3522 5.04393 35.54 4.84321C35.7271 4.64022 35.9581 4.49837 36.233 4.41767C36.5101 4.3363 36.7811 4.33074 37.046 4.401C37.3125 4.46833 37.5461 4.59589 37.7468 4.78369C37.9491 4.96855 38.091 5.19954 38.1724 5.47667C38.2531 5.75153 38.2586 6.02254 38.189 6.28971C38.1217 6.55621 37.9945 6.79096 37.8073 6.99395C37.6218 7.194 37.3905 7.33471 37.1134 7.41609Z" fill="currentColor"/>
<path d="M51.1695 2.54545C51.1269 2.18561 50.954 1.90625 50.651 1.70739C50.348 1.50852 49.9763 1.40909 49.536 1.40909C49.214 1.40909 48.9323 1.46117 48.6908 1.56534C48.4517 1.66951 48.2646 1.81274 48.1297 1.99503C47.9971 2.17732 47.9308 2.38447 47.9308 2.61648C47.9308 2.81061 47.977 2.97751 48.0693 3.11719C48.164 3.2545 48.2848 3.36932 48.4316 3.46165C48.5783 3.55161 48.7322 3.62618 48.8932 3.68537C49.0542 3.74219 49.2021 3.78835 49.3371 3.82386L50.0757 4.02273C50.2651 4.07244 50.4758 4.1411 50.7078 4.22869C50.9422 4.31629 51.1659 4.43584 51.379 4.58736C51.5944 4.73651 51.772 4.92827 51.9117 5.16264C52.0513 5.39702 52.1212 5.68466 52.1212 6.02557C52.1212 6.41856 52.0182 6.77367 51.8122 7.09091C51.6086 7.40814 51.3103 7.66027 50.9173 7.8473C50.5267 8.03433 50.0521 8.12784 49.4933 8.12784C48.9725 8.12784 48.5215 8.0438 48.1404 7.87571C47.7616 7.70762 47.4633 7.47325 47.2455 7.17259C47.03 6.87192 46.9081 6.52273 46.8797 6.125H47.7888C47.8125 6.39962 47.9048 6.62689 48.0658 6.80682C48.2291 6.98437 48.4351 7.11695 48.6837 7.20455C48.9346 7.28977 49.2045 7.33239 49.4933 7.33239C49.8295 7.33239 50.1314 7.27794 50.3989 7.16903C50.6664 7.05777 50.8783 6.90388 51.0345 6.70739C51.1908 6.50852 51.2689 6.27652 51.2689 6.01136C51.2689 5.76989 51.2014 5.57339 51.0665 5.42188C50.9316 5.27036 50.754 5.14725 50.5338 5.05256C50.3137 4.95786 50.0757 4.875 49.82 4.80398L48.9252 4.5483C48.357 4.38494 47.9072 4.15175 47.5757 3.84872C47.2443 3.54569 47.0786 3.14915 47.0786 2.65909C47.0786 2.25189 47.1887 1.89678 47.4088 1.59375C47.6314 1.28835 47.9297 1.05161 48.3037 0.883523C48.6801 0.713068 49.1003 0.627841 49.5644 0.627841C50.0331 0.627841 50.4498 0.711884 50.8144 0.879971C51.1789 1.04569 51.4678 1.27296 51.6808 1.56179C51.8963 1.85062 52.0099 2.1785 52.0218 2.54545H51.1695Z" fill="currentColor"/>
<path d="M56.9547 0.323064L55.9197 7.52177L55.048 7.39643L56.083 0.19773L56.9547 0.323064Z" fill="currentColor"/>
<path d="M59.2097 8.14264L58.3238 7.88252L62.9351 1.65674L63.8073 1.91286L64.3206 9.64335L63.4348 9.38322L63.0743 2.89677L63.0198 2.88076L59.2097 8.14264ZM60.3372 5.51285L63.908 6.56135L63.6879 7.31095L60.1171 6.26246L60.3372 5.51285Z" fill="currentColor"/>
<path d="M64.8021 9.91977L67.8233 3.30427L70.0586 4.3251C70.5754 4.56113 70.9593 4.84317 71.2103 5.17121C71.4623 5.49709 71.5982 5.84932 71.6179 6.22789C71.6376 6.60647 71.5584 6.99064 71.3804 7.38042C71.2024 7.7702 70.9649 8.07945 70.6678 8.30816C70.3708 8.53687 70.0182 8.66213 69.61 8.68394C69.2029 8.7036 68.743 8.59639 68.2305 8.36233L66.4215 7.53622L66.752 6.81265L68.5351 7.62696C68.8882 7.78824 69.1961 7.86638 69.4587 7.86136C69.7234 7.85732 69.9475 7.78267 70.1309 7.63742C70.3175 7.491 70.4713 7.28535 70.5922 7.02047C70.7132 6.7556 70.7694 6.50148 70.7608 6.25812C70.7522 6.01476 70.6638 5.78958 70.4954 5.58257C70.3281 5.37341 70.0657 5.18721 69.7082 5.02395L68.2998 4.38077L65.6032 10.2856L64.8021 9.91977ZM69.2732 8.37005L69.5441 12.0854L68.6137 11.6605L68.3687 7.957L69.2732 8.37005Z" fill="currentColor"/>
<path d="M73.3584 6.84227L73.7808 6.18505L78.3694 9.13399L77.9471 9.79122L76.0232 8.55482L72.5136 14.0158L71.7727 13.5397L75.2823 8.07868L73.3584 6.84227Z" fill="currentColor"/>
<path d="M79.3501 9.87907L80.1123 10.5395L79.4135 14.4261L79.4779 14.4819L83.2255 13.2371L83.9877 13.8975L79.2018 15.4079L77.2391 17.673L76.5735 17.0963L78.5362 14.8312L79.3501 9.87907Z" fill="currentColor"/>
<path d="M84.2887 25.8526L83.0753 23.9646L89.1935 20.0326L90.4607 22.0043C90.8421 22.5978 91.046 23.1844 91.0724 23.764C91.1007 24.3424 90.9592 24.8907 90.6477 25.4088C90.3383 25.9257 89.8669 26.3876 89.2336 26.7947C88.5963 27.2042 87.9746 27.4433 87.3686 27.512C86.7647 27.5793 86.1997 27.4752 85.6737 27.1998C85.1497 26.923 84.688 26.474 84.2887 25.8526ZM84.2087 24.2831L84.9152 25.3824C85.2403 25.8883 85.6073 26.2448 86.0163 26.452C86.4252 26.6591 86.8624 26.7285 87.3278 26.6602C87.7932 26.5918 88.2749 26.3977 88.7728 26.0777C89.2667 25.7603 89.6401 25.4063 89.8929 25.0159C90.1477 24.6242 90.2677 24.2066 90.2528 23.7631C90.2399 23.3183 90.0799 22.8569 89.7727 22.3789L89.0124 21.1959L84.2087 24.2831Z" fill="currentColor"/>
<path d="M86.5894 29.7032L86.2059 28.8634L93.9307 28.2713L94.3084 29.0982L88.8022 34.5486L88.4186 33.7087L93.0847 29.1886L93.0611 29.1369L86.5894 29.7032ZM89.3152 28.8332L90.8612 32.2185L90.1506 32.543L88.6046 29.1577L89.3152 28.8332Z" fill="currentColor"/>
<path d="M97.2867 37.8124L90.3086 39.8614L90.0685 39.0436L94.4308 33.6323L94.4108 33.5642L88.9319 35.1729L88.6838 34.3279L95.6619 32.279L95.902 33.0967L91.53 38.5256L91.55 38.5938L97.0426 36.981L97.2867 37.8124Z" fill="currentColor"/>
<path d="M96.1566 45.2888L96.0313 44.4171C96.2746 44.3295 96.4819 44.2076 96.6532 44.0514C96.8248 43.8976 96.9619 43.72 97.0645 43.5187C97.1698 43.3194 97.2394 43.1049 97.2734 42.8752C97.3073 42.6455 97.3071 42.4111 97.2727 42.1721C97.2101 41.7362 97.0432 41.3572 96.772 41.035C96.5012 40.7152 96.1364 40.4818 95.6776 40.3349C95.2191 40.1904 94.6782 40.1629 94.0549 40.2525C93.4315 40.3422 92.9203 40.5209 92.5211 40.7888C92.1223 41.059 91.838 41.3857 91.6683 41.7688C91.4989 42.1544 91.4455 42.565 91.5082 43.0009C91.5425 43.2399 91.6083 43.4649 91.7056 43.6757C91.8029 43.8865 91.929 44.0729 92.0838 44.2348C92.2413 44.3987 92.4241 44.5303 92.6321 44.6296C92.8427 44.7308 93.076 44.7894 93.3318 44.8052L93.4571 45.6769C93.0798 45.6642 92.7334 45.592 92.4179 45.4604C92.1024 45.3288 91.8241 45.1487 91.5832 44.9203C91.3446 44.6915 91.1495 44.4241 90.9979 44.1183C90.8466 43.8147 90.7449 43.4814 90.6927 43.1182C90.6044 42.5042 90.6759 41.9366 90.9071 41.4155C91.1383 40.8943 91.5107 40.4569 92.0243 40.1032C92.5379 39.7495 93.1743 39.5181 93.9336 39.4089C94.6928 39.2998 95.3687 39.3425 95.9611 39.5372C96.5536 39.7318 97.0341 40.0466 97.4028 40.4815C97.7715 40.9165 97.9999 41.4409 98.0882 42.0548C98.1404 42.4181 98.1367 42.7666 98.0771 43.1004C98.0178 43.4366 97.9048 43.7483 97.738 44.0354C97.5735 44.3221 97.3584 44.5731 97.0928 44.7882C96.8295 45.0031 96.5174 45.1699 96.1566 45.2888Z" fill="currentColor"/>
<path d="M91 46.8924H98.2727V51.2816H97.4915V47.7731H95.0341V51.0543H94.2528V47.7731H91.7812V51.3384H91V46.8924Z" fill="currentColor"/>
<path d="M94.1943 64.0646C94.5516 64.1251 94.8683 64.038 95.1445 63.8033C95.4207 63.5685 95.6208 63.2399 95.7449 62.8174C95.8356 62.5085 95.865 62.2235 95.8331 61.9625C95.8005 61.7037 95.7158 61.4839 95.5789 61.303C95.4413 61.1245 95.2612 61.0025 95.0386 60.9372C94.8524 60.8825 94.6792 60.8797 94.5192 60.929C94.3607 60.9811 94.2166 61.0646 94.0866 61.1795C93.9589 61.295 93.844 61.4216 93.7419 61.5594C93.642 61.6978 93.556 61.8268 93.484 61.9463L93.085 62.599C92.984 62.7667 92.8587 62.9495 92.7093 63.1474C92.5593 63.3476 92.3815 63.5286 92.1761 63.6904C91.9723 63.8551 91.7383 63.9714 91.4741 64.0394C91.2098 64.1074 90.9142 64.0934 90.5871 63.9973C90.21 63.8866 89.8983 63.6877 89.6519 63.4007C89.4049 63.116 89.247 62.7588 89.1783 62.329C89.1089 61.9015 89.1529 61.4197 89.3103 60.8836C89.457 60.3839 89.6647 59.9748 89.9334 59.6565C90.2014 59.3404 90.5103 59.1202 90.8602 58.9959C91.2093 58.8739 91.5787 58.8553 91.9684 58.9401L91.7122 59.8124C91.4421 59.7577 91.198 59.7823 90.98 59.8861C90.7636 59.9928 90.5784 60.1531 90.4243 60.3669C90.2718 60.5837 90.1549 60.8306 90.0735 61.1077C89.9788 61.4303 89.946 61.7353 89.9751 62.0226C90.0065 62.3106 90.0945 62.5573 90.239 62.7626C90.3858 62.9685 90.5864 63.1089 90.8408 63.1836C91.0725 63.2516 91.28 63.2422 91.4634 63.1554C91.6468 63.0686 91.815 62.9329 91.9679 62.7484C92.1208 62.5638 92.2673 62.3589 92.4075 62.1335L92.9049 61.3469C93.2217 60.8478 93.5722 60.4819 93.9563 60.2493C94.3405 60.0166 94.7676 59.9693 95.2378 60.1074C95.6285 60.2221 95.9383 60.4278 96.167 60.7244C96.3973 61.024 96.5404 61.3769 96.5963 61.7831C96.6538 62.1923 96.6172 62.6196 96.4865 63.0648C96.3544 63.5145 96.1564 63.8906 95.8924 64.1931C95.6307 64.4962 95.3312 64.7093 94.9941 64.8324C94.6563 64.9577 94.3096 64.9744 93.9542 64.8824L94.1943 64.0646Z" fill="currentColor"/>
<path d="M94.3479 66.7336L95.0585 67.0581L92.7926 72.0198L92.082 71.6952L93.032 69.615L87.1271 66.9183L87.493 66.1172L93.3978 68.8139L94.3479 66.7336Z" fill="currentColor"/>
<path d="M89.6075 77.1223L89.1314 77.8631L85.0805 75.2598C84.6622 74.991 84.3521 74.6524 84.1502 74.2441C83.9491 73.839 83.8684 73.3974 83.9083 72.9193C83.9502 72.4425 84.1254 71.9641 84.4339 71.4841C84.7423 71.0041 85.1047 70.6461 85.521 70.4099C85.9393 70.175 86.3752 70.0639 86.8285 70.0766C87.2825 70.0926 87.7187 70.235 88.1369 70.5038L92.1878 73.1072L91.7117 73.8481L87.7205 71.2831C87.4218 71.0911 87.1137 70.986 86.7962 70.9677C86.4807 70.9506 86.1781 71.0207 85.8886 71.1779C85.5997 71.3384 85.3458 71.589 85.1269 71.9295C84.9081 72.2701 84.7856 72.6051 84.7597 72.9345C84.7357 73.2653 84.7976 73.5696 84.9454 73.8475C85.094 74.1287 85.3176 74.3653 85.6163 74.5573L89.6075 77.1223Z" fill="currentColor"/>
<path d="M81.2665 75.9836L82.7362 74.2875L88.2326 79.0501L86.6978 80.8214C86.2358 81.3546 85.7304 81.7155 85.1817 81.9041C84.6347 82.0942 84.0688 82.1129 83.4839 81.96C82.9008 81.8087 82.3247 81.4866 81.7558 80.9936C81.1832 80.4975 80.7787 79.9684 80.5421 79.4063C80.3073 78.8457 80.248 78.2743 80.3641 77.692C80.482 77.1113 80.7828 76.5418 81.2665 75.9836ZM82.7499 75.4647L81.8942 76.4523C81.5004 76.9067 81.2617 77.3593 81.1781 77.81C81.0946 78.2608 81.1511 78.6998 81.3479 79.1271C81.5446 79.5544 81.8666 79.9619 82.3139 80.3495C82.7576 80.734 83.2024 80.9925 83.6482 81.1251C84.0958 81.2592 84.5303 81.2567 84.9517 81.1174C85.3749 80.9798 85.7725 80.6962 86.1446 80.2668L87.0654 79.2041L82.7499 75.4647Z" fill="currentColor"/>
<path d="M80.9889 86.4999L76.2263 81.0035L76.8919 80.4268L81.6545 85.9232L80.9889 86.4999Z" fill="currentColor"/>
<path d="M71.2134 88.9414C70.7988 88.2961 70.5569 87.6636 70.4878 87.0438C70.4188 86.424 70.5055 85.8561 70.748 85.3401C70.9904 84.8241 71.3725 84.3984 71.8943 84.063C72.4161 83.7277 72.9621 83.5569 73.5322 83.5508C74.1023 83.5446 74.6549 83.7016 75.19 84.0219C75.7251 84.3422 76.2 84.8249 76.6147 85.4702C77.0294 86.1155 77.2712 86.748 77.3403 87.3678C77.4093 87.9876 77.3226 88.5555 77.0802 89.0715C76.8377 89.5875 76.4556 90.0132 75.9338 90.3485C75.412 90.6839 74.866 90.8546 74.2959 90.8608C73.7258 90.867 73.1732 90.71 72.6381 90.3897C72.103 90.0694 71.6281 89.5867 71.2134 88.9414ZM71.9304 88.4806C72.2709 89.0104 72.6469 89.4005 73.0583 89.6511C73.4678 89.9029 73.8831 90.0258 74.3041 90.0197C74.7232 90.015 75.1179 89.8935 75.4884 89.6555C75.8588 89.4174 76.1342 89.1081 76.3147 88.7277C76.4931 88.3485 76.5538 87.9197 76.4968 87.4413C76.4379 86.9642 76.2382 86.4608 75.8977 85.931C75.5572 85.4012 75.1823 85.0104 74.7728 84.7586C74.3613 84.508 73.946 84.3852 73.527 84.3899C73.1059 84.396 72.7102 84.5181 72.3398 84.7561C71.9693 84.9942 71.6949 85.3028 71.5165 85.682C71.336 86.0625 71.2753 86.4913 71.3343 86.9684C71.3912 87.4468 71.59 87.9508 71.9304 88.4806Z" fill="currentColor"/>
<path d="M61.4746 90.383C61.7495 90.3022 62.0202 90.2956 62.2867 90.3629C62.5539 90.4325 62.7889 90.5608 62.9919 90.748C63.1926 90.9358 63.3334 91.1671 63.4141 91.442C63.4954 91.7191 63.5021 91.9898 63.4341 92.254C63.3645 92.5212 63.2358 92.7551 63.048 92.9558C62.8609 93.1588 62.6299 93.3007 62.3551 93.3814C62.0779 93.4627 61.8069 93.4683 61.542 93.398C61.2755 93.3307 61.0419 93.2032 60.8412 93.0154C60.6389 92.8305 60.497 92.5995 60.4156 92.3224C60.3349 92.0475 60.3294 91.7765 60.399 91.5093C60.4663 91.2428 60.5935 91.0081 60.7807 90.8051C60.9662 90.605 61.1975 90.4643 61.4746 90.383Z" fill="currentColor"/>
</svg>
)